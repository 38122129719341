<template>
  <div id="findpasswordfail">
    <h1>驗證失敗</h1>
    <h3>對不起，我們無法通過您提供的資料確認您的身份，您可以仔細回想您在註冊時填寫的資料，隨時歡迎您回來。</h3>
    <div class="item"><p>重新驗証</p><svg-icon class="icon" name='toright' size=20 color="rgb(113, 113, 113)"></svg-icon></div>
    <div class="item"><p>服務中心</p><svg-icon class="icon" name='toright' size=20 color="rgb(113, 113, 113)"></svg-icon></div>
    <div class="item"><p>線上客服</p><svg-icon class="icon" name='toright' size=20 color="rgb(113, 113, 113)"></svg-icon></div>
    <p class="text">返回登錄首頁</p>
  </div>
</template>

<script>
export default {
  name:'FindPasswordFail',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#findpasswordfail{
  max-width: 600px;
  width: 600px;
  height: 360px;
}
.item{
  position: relative;
  display: flex;
  height: 48px;
  align-items: center;
  width: 100%;
  background: rgb(210, 210, 210);
  justify-content: center;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  box-sizing: border-box;
}
.icon{
  position: absolute;
  right: 20px;
}
p{
  text-align: center;
  box-sizing: border-box;
}
.text{
  margin-top: 60px;
}
</style>
