<template>
  <div id="findpasswordtwo">
    <h1>Forgot Password?</h1>
    <h3>已將驗證碼寄送至您的信箱，請至信箱確認，並填寫正確驗證碼，要是過久未收到或超過驗證時間，請點選再次獲取驗證碼。碰上問題?線上客服</h3>
    <h4>此流程需證明帳號為您本人，您可以選擇以下取得驗證碼:</h4>
    <div class="item"><p>使用郵箱找回密碼</p><svg-icon class="icon" name='toright' size=20 color="rgb(113, 113, 113)"></svg-icon></div>
    <div class="item"><p>使用電話找回密碼</p><svg-icon class="icon" name='toright' size=20 color="rgb(113, 113, 113)"></svg-icon></div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
export default {
  name:'FindPasswordTwo',
  components: {
    SvgIcon
  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#findpasswordtwo{
  max-width: 600px;
  width: 600px;
  height: 360px;
}
h1{
  margin-bottom: 20px;
}
h3{
  color: rgb(113, 113, 113);
  margin-bottom: 30px;
}
.item{
  position: relative;
  display: flex;
  height: 48px;
  align-items: center;
  width: 100%;
  background: rgb(210, 210, 210);
  justify-content: center;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.icon{
  position: absolute;
  right: 20px;
}
</style>
