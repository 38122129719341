<template>
  <div id="verificationcodebox">
    <input type="number" id="ist" maxlength="1" v-model="code.first" oninput="if(value.length>1)value=value.slice(0)" @input="clickEvent(code.first,'sec')">
    <input type="number" id="sec" maxlength="1" v-model="code.sec" oninput="if(value.length>1)value=value.slice(0)" @input="clickEvent(code.sec,'third')">
    <input type="number" id="third" maxlength="1" v-model="code.third" oninput="if(value.length>1)value=value.slice(0,1)" @input="clickEvent(code.third,'four')">
    <input type="number" id="four" maxlength="1" v-model="code.four" oninput="if(value.length>1)value=value.slice(0,1)" @input="clickEvent(code.four,'five')">
    <input type="number" id="five" maxlength="1" v-model="code.five" oninput="if(value.length>1)value=value.slice(0,1)" @input="clickEvent(code.six,'six')">
    <input type="number" id="six" maxlength="1" v-model="code.six" oninput="if(value.length>1)value=value.slice(0,1)">
  </div>
</template>

<script>

export default {
  name:'VerificationCodeBox',
  components: {

  },
  data() {
    return {
      code:{
        first: '',
        sec: '',
        third: '',
        four: '',
        five: '',
        six: ''
      },
      allCode:""
    }
  },
  methods:{
    clickEvent(first,last){
      console.log(first,last)
      if(first!=null){
        document.getElementById(last).focus();
      }
    },
    addAllCode(){
      this.allCode = this.code.first + this.code.sec + this.code.third + this.code.four + this.code.five + this.code.six
      this.$emit('allCode', this.allCode)
    }
  },
  watch:{
    "code.first"(){
      this.addAllCode()
    },
    "code.sec"(){
      this.addAllCode()
    },
    "code.third"(){
      this.addAllCode()
    },
    "code.four"(){
      this.addAllCode()
    },
    "code.five"(){
      this.addAllCode()
    }, 
    "code.six"(){
      this.addAllCode()
    },
  }
}
</script>

<style scoped>
#verificationcodebox{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 400px;
}
input{
  width: 34px;
  height: 40px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #000;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
input[type="number"]{-moz-appearance:textfield;}
</style>
