<template>
  <div id="findpasswordone">
    <h1>Forgot Password?</h1>
    <h3>已將驗證碼寄送至您的信箱，請至信箱確認，並填寫正確驗證碼，要是過久未收到或超過驗證時間，請點選再次獲取驗證碼。碰上問題?線上客服</h3>
    <email-check-item></email-check-item>
  </div>
</template>

<script>

import EmailCheckItem from '@/components/form/EmailCheckItem.vue'
export default {
  name:'FindPasswordOne',
  components: {
    EmailCheckItem,
  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#findpasswordone{
  max-width: 600px;
  width: 600px;
  height: 360px;
}
h1{
  margin-bottom: 20px;
}
h3{
  color: rgb(113, 113, 113);
  margin-bottom: 30px;
}
</style>
