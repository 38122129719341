<template>
  <div id="emailCheckItem">
    <div class="formBox">
      <div class="text">{{$t(`lang.password`)}}</div>
      <input type="password" 
              :placeholder="$t('lang.enterPassword')"
              :modelValue="modelValue"
              :disabled= "disabled" 
              :inputStatus = 'inputStatus'
              :inputIsOk = 'inputIsOk'
              :message = 'message'
              @input="onInput"
              @blur="cancelInput()" >
      <span class="errorMessage">{{message}}</span>

    </div>
    <div class="checkBox" v-if="checkBox">
        <div class="checkBoxLine">
          <div class="checkIcon" v-if="!charactersOk"><svg-icon name="check-ok" size="14" color="#727586"/></div>
          <div class="checkIcon" v-if="charactersOk">-</div>
          <p>At least 8 characters</p>
        </div>
        <div class="checkBoxLine">
          <div class="checkIcon" v-if="!numbersOK"><svg-icon name="check-ok" size="14" color="#727586"/></div>
          <div class="checkIcon" v-if="numbersOK">-</div>
          <p>Contains numbers</p>
        </div>
        <div class="checkBoxLine">
          <div class="checkIcon" v-if="!uppercaseOK"><svg-icon name="check-ok" size="14" color="#727586"/></div>
          <div class="checkIcon" v-if="uppercaseOK">-</div>
          <p>Cintains uppercase and lowercase characters</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name:'EmailCheckItem',
  data(){
    return{
      message:'',
      numbersOK:true,
      uppercaseOK:true,
      charactersOk:true,
    }
  },
  props:{
    title:{
      type: String,
      default: 'Password'
    },
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: "text"
    },
    name:{
      type: String,
      default: ""
    },
    disabled:{
      type: Boolean,
      default: false
    },
    maxlength:{
      type: String,
      default: '60'
    },
    inputStatus:{
      type: Boolean,
      default: true
    },
    inputIsOk:{
      type: Boolean,
      default: false
    },
    placeholder:{
      type: String,
      default: "Please Enter Your Password"
    },
    checkBox:{
      type: Boolean,
      default: false
    }
  },
  methods:{
    onInput(e){
      const number = /\d/
      const word= /^(?=.*[a-z])(?=.*[A-Z])[^]/
      if(number.test(e.target.value)){
        this.numbersOK= false
      } else {
        this.numbersOK= true
      }
      if(word.test(e.target.value)) {
        this.uppercaseOK= false
      } else {
        this.uppercaseOK= true
      }
      if(e.target.value.length>7) {
        this.charactersOk=false
      } else {
        this.charactersOk=true
      }
      this.$emit('update:modelValue', e.target.value)
      const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      if(e.target.value == ''){
        this.message = "Not null"
      } else if(!password.test(e.target.value)){
        this.message = "Bad password format"
      } else {
        this.message = ""
      }

    },
    cancelInput(){
      const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      if(password.test(this.modelValue)){
        this.$emit('passOK',true)
      }
    },
  },
}
</script>

<style scoped>
input{
  outline: none;
  width: 100%;
  border: none;
  background: none;
  height: 40px;
  font-size: 18px;
}
.text{
  font-size: 24px;
}
.formBox{
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
  box-sizing: border-box;
  min-width: 300px;
}
.errorMessage{
  position: absolute;
  bottom: -26px;
  right: 20px;
  color: red;
}
.checkBox p{
  color: #727586;
  margin-left: 20px;

}
.checkBoxLine{
  display: flex;
  align-items: center;
}
.checkBox{
  margin-bottom: 20px;
}
</style>
