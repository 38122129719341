<template>
  <div id="findpasswordemailone">
    <h1>驗證您的身份</h1>
    <h4>已將驗證碼寄送至您的信箱，請至信箱確認，並填寫正確驗證碼，要是過久未收到或超過驗證時間，請點選再次獲取驗證碼。碰上問題?線上客服</h4>
    <verification-code-box></verification-code-box>
  </div>
</template>

<script>
import VerificationCodeBox from '../../../../components/form/VerificationCodeBox.vue'
export default {
  name:'FindPasswordEmailOne',
  components: {
    VerificationCodeBox

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#findpasswordemailone{
  max-width: 600px;
  width: 600px;
  height: 360px;
}
h4{
  margin-bottom: 40px;
  color: rgb(146, 146, 146);
  margin-top: 20px;
}
</style>
