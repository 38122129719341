<template>
  <div id="findpassword">
    <div class="content">
      <find-password-one v-if="isShow==1"></find-password-one>
      <find-password-two v-if="isShow==2"></find-password-two>
      <find-password-email-one v-if="isShow==3"></find-password-email-one>
      <find-password-email-two v-if="isShow==4"></find-password-email-two>
      <find-password-email-three v-if="isShow==5"></find-password-email-three>
      <find-password-fail v-if="isShow==6"></find-password-fail>
      <btn-submit :width="width" @click="goNext()">Next</btn-submit>
    </div>
  </div>
</template>

<script>
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import FindPasswordOne from './aboutPassword/FindPasswordOne.vue'
import FindPasswordTwo from './aboutPassword/FindPasswordTwo.vue'
import FindPasswordEmailOne from './aboutPassword/FindPasswordEmailOne.vue'
import FindPasswordEmailTwo from './aboutPassword/FindPasswordEmailTwo.vue'
import FindPasswordEmailThree from './aboutPassword/FindPasswordEmailThree.vue'
import FindPasswordFail from './aboutPassword/FindPasswordFail.vue'
export default {
  name:'FindPassword',
  components: {
    FindPasswordOne,
    BtnSubmit,
    FindPasswordTwo,
    FindPasswordEmailOne,
    FindPasswordEmailTwo,
    FindPasswordEmailThree,
    FindPasswordFail
  },
  data() {
    return {
      width: 460,
      isShow: 1
    }
  },
  methods:{
    goNext(){
      this.isShow ++
    }
  },
}
</script>

<style scoped>
#findpassword{
  width: 100vw;
  height: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content{
  max-width: 600px;
  width: 600px;
  height: 500px;
}
</style>
