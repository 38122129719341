<template>
  <div id="findpasswordemailtwo">
    <h1>密碼提示問題</h1>
    <h4>請填寫您設置的密碼問題以幫助我們確認您的身份</h4>
    <div class="questionBox"><h3>你媽貴姓？</h3> </div>
    <input-text></input-text>
  </div>
</template>

<script>
import InputText from '@/components/form/InputText.vue'
export default {
  name:'FindPasswordEmailTwo',
  components: {
    InputText

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#findpasswordemailtwo{
  max-width: 600px;
  width: 600px;
  height: 300px;
}
.questionBox{
  width: 500px;
  height: 40px;
  line-height: 40px;
  background: rgb(168, 168, 168);
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  border-radius: 10px;
}
h1{
  margin-bottom: 10px;
}
</style>
