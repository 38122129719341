<template>
  <div id="findpasswordemailthree">
    <h1>修改密碼</h1>
    <div class="userItem"></div>
    <div class="pageTwo">
      <password-check-item v-model="changePassword.password" :checkBox="true"/>
      <password-check-item title="Confirm Password" placeholder="Enter Your Password Again" />
    </div>
  </div>
</template>

<script>
import PasswordCheckItem from '@/components/form/PasswordCheckItem.vue'
export default {
  name:'FindPasswordEmailThree',
  components: {
    PasswordCheckItem,
  },
  data() {
    return {
      changePassword: ''
    }
  },
  methods:{},
}
</script>

<style scoped>
#findpasswordemailthree{
  max-width: 600px;
  width: 600px;
  height: 500px;
}
.userItem{
  margin-top: 30px;
  height: 60px;
  width: 100%;
  background: rgb(193, 193, 193);
  border-radius: 5px;
  margin-bottom: 20px;
}
</style>
