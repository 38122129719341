<template>
  <div id="emailCheckItem">
    <div class="formBox">
      <h2 class="text"><slot></slot></h2>
      <input type="email" 
              :placeholder="placeholder"
              :modelValue="modelValue"
              :disabled= "disabled" 
              :inputStatus = 'inputStatus'
              :inputIsOk = 'inputIsOk'
              :message = 'message'
              :style="{color: color}"
              @input="onInput"
              @blur="cancelInput()">
      <span class="errorMessage">{{message}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name:'EmailCheckItem',
  data(){
    return{
      message:''
    }
  },
  props:{
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: "text"
    },
    name:{
      type: String,
      default: ""
    },
    disabled:{
      type: Boolean,
      default: false
    },

    maxlength:{
      type: String,
      default: '60'
    },
    inputStatus:{
      type: Boolean,
      default: true
    },
    inputIsOk:{
      type: Boolean,
      default: false
    },
    placeholder:{
      type: String,
      default: "Please Enter Your Email Address"
    },
    color:{
      type: String,
      default: "#000"
    }
  },
  methods:{
    onInput(e){
      this.$emit('update:modelValue', e.target.value)
    },
    cancelInput(){
      if(this.modelValue == ''){
        this.message = "Not null"
      } 
      else {
        this.message = ""
      }
    },
  },
}
</script>

<style scoped>
input{
  outline: none;
  width: 100%;
  border: none;
  background: none;
  height: 40px;
  font-size: 18px;
}
.text{
  font-size: 24px;
}
.formBox{
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
  box-sizing: border-box;
  min-width: 100px;
}
.errorMessage{
  position: absolute;
  bottom: -26px;
  right: 20px;
  color: red;
}
</style>
